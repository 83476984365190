<template>
  <div id="profile">
    <div class="border-bottom pb-4">
      <h5>{{fullName}}</h5>
      <p class="text-muted mb-0">I have started my career as a trainee and prove my self and achieve all the
        milestone with good guidance and reach up to the project manager. In this journey, I understand all the
        procedure which make me a good developer, team leader, and a project manager.</p>
    </div>
    <div class="border-bottom pb-4">
      <div class="row">
        <div class="col-12 mt-4">
          <h5>Personal Details :</h5>
          <div class="mt-4">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="media align-items-center">
                  <mail-icon class="fea icon-ex-md text-muted mr-3"></mail-icon>
                  <div class="media-body">
                    <h6 class="text-primary mb-0">Email :</h6>
                    <a href="javascript:void(0)" class="text-muted">kristajoseph0203@mail.com</a>
                  </div>
                </div>
                <div class="media align-items-center mt-3">
                  <bookmark-icon class="fea icon-ex-md text-muted mr-3"></bookmark-icon>
                  <div class="media-body">
                    <h6 class="text-primary mb-0">Skills :</h6>
                    <a href="javascript:void(0)" class="text-muted">html</a>, <a href="javascript:void(0)"
                      class="text-muted">css</a>, <a href="javascript:void(0)" class="text-muted">js</a>, <a
                      href="javascript:void(0)" class="text-muted">mysql</a>
                  </div>
                </div>
                <div class="media align-items-center mt-3">
                  <italic-icon class="fea icon-ex-md text-muted mr-3"></italic-icon>
                  <div class="media-body">
                    <h6 class="text-primary mb-0">Language :</h6>
                    <a href="javascript:void(0)" class="text-muted">English</a>, <a href="javascript:void(0)"
                      class="text-muted">Japanese</a>, <a href="javascript:void(0)" class="text-muted">Chinese</a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="media align-items-center mt-3">
                  <gift-icon class="fea icon-ex-md text-muted mr-3"></gift-icon>
                  <div class="media-body">
                    <h6 class="text-primary mb-0">Birthday :</h6>
                    <p class="text-muted mb-0">2nd March, 1996</p>
                  </div>
                </div>
                <div class="media align-items-center mt-3">
                  <map-pin-icon class="fea icon-ex-md text-muted mr-3"></map-pin-icon>
                  <div class="media-body">
                    <h6 class="text-primary mb-0">Location :</h6>
                    <a href="javascript:void(0)" class="text-muted">Beijing, China</a>
                  </div>
                </div>
                <div class="media align-items-center mt-3">
                  <phone-icon class="fea icon-ex-md text-muted mr-3"></phone-icon>
                  <div class="media-body">
                    <h6 class="text-primary mb-0">Cell No :</h6>
                    <a href="javascript:void(0)" class="text-muted">(+12) 1254-56-4896</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    MailIcon,
    BookmarkIcon,
    ItalicIcon,
    GlobeIcon,
    GiftIcon,
    MapPinIcon,
    PhoneIcon,
  } from "vue-feather-icons";
  export default {
    components: {
      MailIcon,
      BookmarkIcon,
      ItalicIcon,
      GlobeIcon,
      GiftIcon,
      MapPinIcon,
      PhoneIcon,
    },
    computed: {
      user() {
        return this.$store.state.auth.user;
      },
      fullName() {
        return this.user ? `${this.user.name} ${this.user.lastName}` : "";
      },
    }
  };
</script>